<template>
  <div class="w-full overflow-hidden">
    <div class="max-w-container relative mx-auto h-[25rem]">
      <svg
        id="Isolation_Mode"
        class="absolute -right-[120rem] h-[25rem]"
        data-name="Isolation Mode"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 1 595.28 45.9"
      >
        <path
          id="Path_3622"
          data-name="Path 3622"
          fill="currentColor"
          d="m595.28,0v11.9h-200.56v-2.91c0-.67-.54-1.2-1.2-1.21-.3,0-.59.11-.81.31l-8.79,7.9c-1.41,1.27-1.53,3.44-.26,4.86.08.09.17.18.26.26l8.79,7.9c.5.44,1.26.4,1.7-.09.2-.22.31-.51.31-.8,0,0,0-1.77.01-1.77v-2.15c0-.45.36-.82.81-.82.2,0,.4.07.55.21l3.06,2.76.27.24,6.78,6.1c1.08.97,1.16,2.62.2,3.7-.06.07-.13.13-.2.2l-6.78,6.1-.47.42-2.87,2.58c-.33.3-.85.28-1.15-.05-.14-.15-.21-.35-.21-.55v-3.29H0V.62"
        />
      </svg>
    </div>
  </div>
</template>
